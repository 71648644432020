import React from 'react';

import {
  AccessibilityWrapper,
  AccessibilityButton,
  TimeoutModal,
  useTemplate,
  Footer,
} from '@fingermarkglobal/cringer.components';

import LogoImage from '../../assets/images/Taco-Bell-symbol-logo-purple.svg';

import { Header } from '../../components/stateless/header';
import { ArrowButton } from '../../components/stateless/arrow-button';
import { BasketCount } from '../../components/stateless/basket-count';

const CustomDefaultTemplate = ({
  children,
  timeoutEnabled = true,
  footerEnabled = true,
  headerEnabled = true,
  showCategoriesHeader = false,
  templateOverride = false,
  showAccessibilityButton = true,
  showOnlyBottom = false,
  enableCustomCancelOrder = false,
  isPriceOnEnd = true,
  isPreviousActionOnBottom = false,
  isPreviousActionEnabled = false,
}) => {
  const {
    header,
    footer,
    modalAccessibilityClass,
    isTimeoutEnabled,
    timeoutActionOverride,
    t,
    isOnline,
    isCouponEnabled,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const { isPreviousActionEnabled: isPreviousActionEnabledFooter } = footer;

  const enablePreviousActionOnFooter =
    isPreviousActionEnabledFooter !== undefined && isPreviousActionEnabledFooter !== null
      ? isPreviousActionEnabledFooter
      : isPreviousActionEnabled;

  return (
    <div className={`w-full bottom-0 absolute overflow-y-hidden h-full bg-default`}>
      <AccessibilityWrapper
        isAccessibilityButtonEnabled={showAccessibilityButton}
        LogoImage={LogoImage}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && (
            <Header
              {...header}
              t={t}
              showCategoriesHeader={showCategoriesHeader}
              isCouponEnabled={isCouponEnabled}
            />
          )}

          <div className="flex flex-col h-full overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              showBottom={!showOnlyBottom}
              enableCustomCancelOrder={enableCustomCancelOrder}
              isBasketCountOnBottom={true}
              isNextActionOnBottom={true}
              isQuantityControllerOnBottom={true}
              isPriceOnEnd={isPriceOnEnd}
              CustomSecondaryButton={ArrowButton}
              BasketCount={BasketCount}
              isPreviousActionOnBottom={isPreviousActionOnBottom}
              isPreviousActionEnabled={enablePreviousActionOnFooter}
            />
          )}

          {showAccessibilityButton && (
            <div className="flex flex-col items-start justify-center">
              <AccessibilityButton className="absolute bottom-0 z-10 block w-24 h-24 p-2 m-4 mt-8 mb-8 bg-white rounded-full shadow-md" />
            </div>
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
