import React from 'react';

import {
  ImageButton,
  HomeCustomisation,
  BannerWarning,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackground from '../../../assets/images/background-home.png';

const SecondaryHome = ({
  t = () => {},
  className = '',
  healthMsg = true,
  accessibilityEnabled = false,
  primaryAction,
  secondaryAction,
  hasBothActionButtons,
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackground,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="secondary-home"
        className={`flex z-10 flex-col justify-between items-center h-full w-full overflow-y-hidden ${className}`}
      >
        <HomeCustomisation.HeroImage />

        <div
          data-test="home-upper"
          className={`flex flex-col justify-center items-center w-full space-y-16`}
        >
          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              className={`w-4/5 ${accessibilityEnabled ? 'mt-143' : 'mt-286'}`}
              data-test="banner-health"
              image={WashHandsImage}
            />
          )}

          <div
            data-test="home-services"
            className={`${
              hasBothActionButtons ? 'grid grid-cols-2 gap-16 w-4/5' : 'justify-center w-2/5'
            }  ${accessibilityEnabled ? 'mt-213' : 'mt-356'}`}
          >
            {!!secondaryAction && (
              <ImageButton
                dataTest="button-secondary"
                className={`bg-eat-in ${hasBothActionButtons ? '' : 'important-h-24'}`}
                onClick={secondaryAction}
              />
            )}

            {!!primaryAction && (
              <ImageButton
                dataTest="button-primary"
                className={`bg-take-away ${hasBothActionButtons ? '' : 'important-h-24'}`}
                onClick={primaryAction}
              />
            )}
          </div>
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
