// Routes is for the applications workflow definitions.
import React from 'react';

import { RouteBuilder, base } from '@fingermarkglobal/router';

import { CustomHomeView } from './src/views/home';
import { CustomDefaultTemplate } from './src/templates/default';

import { version } from './package.json';
import mockSettings from './mock-settings.json';

const config = {
  ...base,
  home: {
    ...base.home,
    View: CustomHomeView,
    config: {
      ...base.home.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        customBackgroundImage: 'bg-home',
      },
      provider: {
        allowedLanguages: ['en'],
      },
    },
  },
  reset: {
    ...base.reset,
    config: { ...base.reset.config, template: { headerEnabled: false, footerEnabled: false } },
  },
  beacon: {
    ...base.beacon,
    config: {
      ...base.beacon.config,
      provider: { primaryPath: '/categories', secondaryPath: '/reset' },
      template: { headerEnabled: false, enableCustomCancelOrder: true, isPriceOnEnd: false },
    },
  },
  coupon: {
    ...base.coupon,
    config: {
      ...base.coupon.config,
      template: {
        footerEnabled: false,
      },
    },
  },
  categories: {
    ...base.categories,
    config: { ...base.categories.config, provider: { showPriceWarning: false } },
  },
  category: {
    ...base.category,
    config: {
      ...base.category.config,
      provider: { displayDescription: true, showPriceWarning: false },
      template: { headerEnabled: true, showCategoriesHeader: true },
    },
  },
  product: {
    ...base.product,
    config: {
      ...base.product.config,
      provider: { showPriceWarning: false },
      template: { headerEnabled: true, showCategoriesHeader: true },
    },
  },
  combo: {
    ...base.combo,
    config: {
      ...base.combo.config,
      provider: { showPriceWarning: false },
      template: { headerEnabled: true, showCategoriesHeader: true },
    },
  },
  composed: {
    ...base.composed,
    config: { ...base.composed.config, provider: { showPriceWarning: false } },
  },
  validate: {
    ...base.validate,
    config: {
      ...base.validate.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  cart: {
    ...base.cart,
    config: {
      ...base.cart.config,
      provider: { showPriceWarning: false, showCategoriesHeader: true },
      template: { headerEnabled: true, showCategoriesHeader: true },
    },
  },
  payment: {
    ...base.payment,
    config: {
      ...base.payment.config,
      template: {
        headerEnabled: false,
        footerEnabled: true,
        enableCustomCancelOrder: true,
        isPriceOnEnd: false,
        isPreviousActionOnBottom: true,
      },
      provider: {
        isPreviousActionEnabled: false,
      },
    },
  },
  card: {
    ...base.card,
    config: {
      ...base.card.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  refund: {
    ...base.refund,
    config: {
      ...base.refund.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  confirmation: {
    ...base.confirmation,
    config: {
      ...base.confirmation.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  receipt: {
    ...base.receipt,
    config: {
      ...base.receipt.config,
      template: {
        headerEnabled: false,
        isPriceOnEnd: false,
      },
    },
  },
  admin: {
    ...base.admin,
    config: {
      ...base.admin.config,
      provider: { version },
      template: { headerEnabled: false, footerEnabled: false, showAccessibilityButton: false },
    },
  },
  notify: {
    ...base.notify,
    config: {
      ...base.notify.config,
      template: { headerEnabled: false, footerEnabled: false, timeoutEnabled: false },
    },
  },
  auth: {
    ...base.auth,
    config: {
      ...base.auth.config,
      template: { headerEnabled: false, footerEnabled: false, showAccessibilityButton: false },
    },
  },
  lock: {
    ...base.lock,
    config: {
      ...base.lock.config,
      provider: { isLogoEnabled: true },
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        showAccessibilityButton: false,
        customBackgroundImage: 'bg-lock',
      },
    },
  },
  sms: {
    ...base.sms,
    config: { ...base.sms.config, template: { headerEnabled: false, footerEnabled: false } },
  },
  customer: {
    ...base.customer,
    config: {
      ...base.customer.config,
      template: {
        ...base.customer.config.template,
        footerEnabled: true,
        headerEnabled: true,
        showCategoriesHeader: true,
      },
      provider: { isFirstNameRequired: true, isLastNameRequired: true },
    },
  },
  transactions: {
    ...base.transactions,
    config: {
      ...base.transactions.config,
      template: { headerEnabled: false, footerEnabled: false },
    },
  },
  loyalty: {
    ...base.loyalty,
    config: {
      ...base.loyalty.config,
    },
  },
  offers: {
    ...base.offers,
    config: {
      ...base.offers.config,
    },
  },
};

const Routes = () => (
  <RouteBuilder
    Template={CustomDefaultTemplate}
    root={{
      overwriteSettings: mockSettings,
      resolverProps: {
        isRetryActive: true,
      },
    }}
    config={config}
  />
);

export { Routes };
